import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

const routes = [
  {
    path: '/ja/',
    component: () => import('@/views/home_ja'),
    redirect: '/ja/home',
    meta: {},
    children: [
      {
        path: 'home',
        component: () => import('@/views/home_ja/HomeDetail.vue'),
        meta: {
          isactive: '11'
        }
      },
      {
        path: 'up',
        component: () => import('@/views/home_ja/HomeUp.vue'),
        meta: {
          isactive: '12'
        }
      },
      // 有效条款
      {
        path: 'clause',
        component: () => import('@/views/home_ja/HomeClause.vue'),
        meta: {
          isactive: '13'
        }
      },
      // 隐私声明
      {
        path: 'statement',
        component: () => import('@/views/home_ja/HomeStatement.vue'),
        meta: {
          isactive: '14'
        }
      },
      // 招聘
      {
        path: 'advertise',
        component: () => import('@/views/home_ja/HomeAdvertise.vue'),
        meta: {
          isactive: '15'
        }
      },
      // 关于我们
      {
        path: 'about',
        component: () => import('@/views/home_ja/HomeAbout.vue'),
        meta: {
          isactive: '16'
        }
      },
      // 案例
      {
        path: 'case',
        component: () => import('@/views/home_ja/HomeCase.vue'),
        meta: {
          isactive: '17'
        }
      },
      // 行业新闻
      {
        path: 'news',
        component: () => import('@/views/home_ja/HomeNews.vue'),
        meta: {
          isactive: '18'
        }
      },
      // 解决方案
      {
        path: 'fixed',
        component: () => import('@/views/home_ja/HomeFixed.vue'),
        meta: {
          isactive: '19'
        }
      },
      // 技术支持
      {
        path: 'skill',
        component: () => import('@/views/home_ja/HomeSkill.vue'),
        meta: {
          isactive: '110'
        }
      },
      // 集团业务
      {
        path: 'group',
        component: () => import('@/views/home_ja/HomeGroup.vue'),
        meta: {
          isactive: '111'
        }
      },

      // 新闻内容
      {
        path: 'news-20160616',
        component: () => import('@/views/news_ja/new-20160616.vue'),
        meta: {
          isactive: '1801'
        }
      },
      {
        path: 'news-20181112',
        component: () => import('@/views/news_ja/new-20181112.vue'),
        meta: {
          isactive: '1802'
        }
      },
      {
        path: 'news-20200311',
        component: () => import('@/views/news_ja/new-20200311.vue'),
        meta: {
          isactive: '1803'
        }
      },
      {
        path: 'news-20210331',
        component: () => import('@/views/news_ja/new-20210331.vue'),
        meta: {
          isactive: '1804'
        }
      },
      {
        path: 'news-20211222',
        component: () => import('@/views/news_ja/new-20211222.vue'),
        meta: {
          isactive: '1805'
        }
      },
      {
        path: 'news-20220407',
        component: () => import('@/views/news_ja/new-20220407.vue'),
        meta: {
          isactive: '1806'
        }
      },
      {
        path: 'news-20221008',
        component: () => import('@/views/news_ja/new-20221008.vue'),
        meta: {
          isactive: '1807'
        }
      },
      {
        path: 'news-20221213',
        component: () => import('@/views/news_ja/new-20221213.vue'),
        meta: {
          isactive: '1808'
        }
      },
      {
        path: 'news-20240910',
        component: () => import('@/views/news_ja/new-20240910.vue'),
        meta: {
          isactive: '1809'
        }
      }
      ,
      {
        path: 'news-20240717',
        component: () => import('@/views/news_ja/new-20240717.vue'),
        meta: {
          isactive: '1809'
        }
      }
      ,
      {
        path: 'news-20240612',
        component: () => import('@/views/news_ja/new-20240612.vue'),
        meta: {
          isactive: '1809'
        }
      }
      ,
      {
        path: 'news-20241101',
        component: () => import('@/views/news_ja/new-20241101.vue'),
        meta: {
          isactive: '1809'
        }
      }
	  
    ]
  },
  // 首页
  {
    path: '/',
    component: () => import('@/views/home'),
    redirect:'/home',
    meta: {},
    children: [
      {
        path: 'home',
        component: () => import('@/views/home/HomeDetail.vue'),
        meta: {
          isactive: '1'
        }
      },
      {
        path: 'up',
        component: () => import('@/views/home/HomeUp.vue'),
        meta: {
          isactive: '2'
        }
      },
      // 有效条款
      {
        path: 'clause',
        component: () => import('@/views/home/HomeClause.vue'),
        meta: {
          isactive: '3'
        }
      },
      // 隐私声明
      {
        path: 'statement',
        component: () => import('@/views/home/HomeStatement.vue'),
        meta: {
          isactive: '4'
        }
      },
      // 招聘
      {
        path: 'advertise',
        component: () => import('@/views/home/HomeAdvertise.vue'),
        meta: {
          isactive: '5'
        }
      },
      // 关于我们
      {
        path: 'about',
        component: () => import('@/views/home/HomeAbout.vue'),
        meta: {
          isactive: '6'
        }
      },
      // 案例
      {
        path: 'case',
        component: () => import('@/views/home/HomeCase.vue'),
        meta: {
          isactive: '7'
        }
      },
      // 行业新闻
      {
        path: 'news',
        component: () => import('@/views/home/HomeNews.vue'),
        meta: {
          isactive: '8'
        }
      },
      // 解决方案
      {
        path: 'fixed',
        component: () => import('@/views/home/HomeFixed.vue'),
        meta: {
          isactive: '9'
        }
      },
      // 技术支持
      {
        path: 'skill',
        component: () => import('@/views/home/HomeSkill.vue'),
        meta: {
          isactive: '10'
        }
      },
      // 集团业务
      {
        path: 'group',
        component: () => import('@/views/home/HomeGroup.vue'),
        meta: {
          isactive: '11'
        }
      },
	  
	  // 新闻内容
      {
        path: 'news-20160616',
        component: () => import('@/views/news/new-20160616.vue'),
        meta: {
          isactive: '801'
        }
      },
      {
        path: 'news-20181112',
        component: () => import('@/views/news/new-20181112.vue'),
        meta: {
          isactive: '802'
        }
      },
      {
        path: 'news-20200311',
        component: () => import('@/views/news/new-20200311.vue'),
        meta: {
          isactive: '803'
        }
      },
      {
        path: 'news-20210331',
        component: () => import('@/views/news/new-20210331.vue'),
        meta: {
          isactive: '804'
        }
      },
      {
        path: 'news-20211222',
        component: () => import('@/views/news/new-20211222.vue'),
        meta: {
          isactive: '805'
        }
      },
      {
        path: 'news-20220407',
        component: () => import('@/views/news/new-20220407.vue'),
        meta: {
          isactive: '806'
        }
      },
      {
        path: 'news-20221008',
        component: () => import('@/views/news/new-20221008.vue'),
        meta: {
          isactive: '807'
        }
      },
      {
        path: 'news-20221213',
        component: () => import('@/views/news/new-20221213.vue'),
        meta: {
          isactive: '808'
        }
      },
      {
        path: 'news-20240910',
        component: () => import('@/views/news/new-20240910.vue'),
        meta: {
          isactive: '809'
        }
      }
      ,
      {
        path: 'news-20240717',
        component: () => import('@/views/news/new-20240717.vue'),
        meta: {
          isactive: '810'
        }
      }
      ,
      {
        path: 'news-20240612',
        component: () => import('@/views/news/new-20240612.vue'),
        meta: {
          isactive: '812'
        }
      }
      ,
      {
        path: 'news-20241101',
        component: () => import('@/views/news/new-20241101.vue'),
        meta: {
          isactive: '813'
        }
      }
	  
    ]
  }
]

const router = new VueRouter({
  routes
})

export default router
